<template>
  <el-container>
    <el-header
      style="background-color: #409eff; color: #fff; line-height: 60px"
    >
      <div class="header-box">
        <span>运维管理系统</span>
        <span style="cursor: pointer" @click="onExit"> 退出账号 </span>
      </div>
    </el-header>
    <el-container class="el-container-h">
      <el-aside width="200px" style="border-right: solid 1px #e6e6e6">
        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          style="border: none"
          @open="handleOpen"
          @close="handleClose"
        >
          <el-menu-item index="2">
            <i class="el-icon-s-custom"></i>
            <template #title>用户列表</template>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
export default {
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    onExit() {
      sessionStorage.removeItem("eman");
      sessionStorage.removeItem("drowssap");
      sessionStorage.removeItem("token");
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>
<style lang="scss">
.el-container-h {
  height: calc(100vh - 60px);
}
.header-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
